import React from 'react';
import connect from 'Shared/connect';
import { isBrowser, Root, CurrentPage, URLX } from '@polarnopyret/scope';
import MainHeader from './Header';
import SimpleHeader from './Header/SimpleHeader';
import MainFooter from './Footer';
import AccountBox from './AccountBox';
import Loader from './Loader';
import NetworkError from './NetworkError';
import { TrayProvider, Tray } from './Tray';
import { MainMenuType, PageType } from 'Shared/State';
import currentPageIsCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';
import CheckoutPageViewModelType from 'Checkout/Pages/Checkout/CheckoutPageViewModel.type';
import currentPageIsMarket from 'Markets/current-page-is-marketpage';
import { isCompact } from 'Shared/Viewport';
import ErrorBoundary from 'Shared/ErrorBoundary';
import AppShellData from 'AppShell/AppShellContextData.type';
import { epiPropertyValue } from '@polarnopyret/scope-episerver';
import currentPageIsLogin from 'Account/Login/current-page-is-login';
import currentPageIsRegister from 'Account/Register/current-page-is-register';
import currentPageIsResetPassword from 'ResetPassword/current-page-is-reset-password';
import currentPageIsStoreLanding from 'Stores/current-page-is-storelandingpage';
import currentPageIsStoreSelection from 'PickUpInStore/current-page-is-storeselectionpage';
import { equalsBaseUrl } from 'Shared/utils';

type CheckoutPageType = CheckoutPageViewModelType & PageType;

type ConnectedPropType = {
  currentPage: PageType;
  mainMenu: MainMenuType;
  showLoadingSpinner: boolean;
  currentBreakpoint: number;
  quickSearchOpen: boolean;
  accountBoxIsOpen: boolean;
  appShellData: AppShellData;
};

type PropType = ConnectedPropType;

class Layout extends React.Component<PropType> {
  render() {
    const { currentPage, appShellData } = this.props;
    renderMeta(this.props.currentPage);
    addCookieConsentCallback(currentPage, appShellData);
    const isMobile = isCompact(this.props.currentBreakpoint);
    const isCheckoutPage = currentPageIsCheckout(currentPage);
    const isMarketPage = currentPageIsMarket(currentPage);
    const isLoginPage = currentPageIsLogin(currentPage);
    const isRegisterPage = currentPageIsRegister(currentPage);
    const isResetPasswordPage = currentPageIsResetPassword(currentPage);
    const isStoreLandingPage = currentPageIsStoreLanding(currentPage);
    const isStoreSelectionPage = currentPageIsStoreSelection(currentPage);
    const hideHeaderAndFooterAndMenu = isMarketPage || isStoreSelectionPage;
    const hideFooter = isLoginPage || isRegisterPage || isResetPasswordPage || isStoreLandingPage || isStoreSelectionPage;

    return (
      <ErrorBoundary>
        <TrayProvider>
          <Root>
            <div>
              {!hideHeaderAndFooterAndMenu && (
                <ErrorBoundary>
                  {isCheckoutPage ? (
                    <SimpleHeader
                      isCompact={isMobile}
                      returnToShopLink={(currentPage as CheckoutPageType).backToShoppingLink}
                      returnToShopLinkLabel={epiPropertyValue(
                        (currentPage as CheckoutPageType).page.returnToShopLinkLabel,
                      )}
                      pageHeading={(currentPage as CheckoutPageType).heading}
                    />
                  ) : (
                    <MainHeader />
                  )}
                </ErrorBoundary>
              )}

              <div>
                <ErrorBoundary>
                  <CurrentPage />
                </ErrorBoundary>
              </div>
              <ErrorBoundary reloadCurrentPage>
                <AccountBox isCompact={isMobile} />
              </ErrorBoundary>
              <ErrorBoundary>{!(hideHeaderAndFooterAndMenu || hideFooter) && <MainFooter />}</ErrorBoundary>
              <ErrorBoundary>
                <Loader visible={this.props.showLoadingSpinner} />
                <NetworkError reload={this.props.currentPage.reload} loadFailure={this.props.currentPage.loadFailure} />
              </ErrorBoundary>
              <Tray />
            </div>
          </Root>
        </TrayProvider>
      </ErrorBoundary>
    );
  }
}

function addCookieConsentCallback(currentPage: PageType, appShellData: AppShellData) {
  if (isBrowser()) {
    const cb = (window as any).Cookiebot;
    if (cb && !cb.hasResponse) {
      let callbackScript = document.getElementById('cookie-callback') as HTMLScriptElement;
      if (!callbackScript) {
        callbackScript = document.createElement('script');
        callbackScript.id = 'cookie-callback';
        callbackScript.type = 'text/javascript';
      } else {
        callbackScript.parentElement.removeChild(callbackScript);
      }

      callbackScript.innerHTML =
        'function CookiebotCallback_OnAccept() { window.dataLayer.push(window.dataLayerLastAction); window.dataLayerLastAction = null; };';
      document.body.appendChild(callbackScript);
    }
  }
}

function renderMeta(currentPage: PageType) {
  if (isBrowser()) {
    const canonicalResource: {} = currentPage;

    let canonical = document.getElementById('link-canonical') as HTMLLinkElement;
    if (isCanonicalResource(canonicalResource)) {
      if (!canonical) {
        canonical = document.createElement('link');
        canonical.id = 'link-canonical';
        canonical.rel = 'canonical';
        canonical.href = canonicalResource.canonicalUrl;
        document.head.appendChild(canonical);
      }
      canonical.href = canonicalResource.canonicalUrl;
    } else if (canonical) {
      canonical.parentElement.removeChild(canonical);
    }

    const newTitle = ((currentPage.meta && currentPage.meta.title) || '') + ' | ' + formatHost(location.host);
    if (document.title === newTitle) {
      return;
    }
    document.title = newTitle;
    Array.from(document.querySelectorAll('meta[data-dynamic]')).forEach((node) => {
      node.parentElement.removeChild(node);
    });

    Object.keys((currentPage.meta && currentPage.meta.elements) || {}).forEach((name) => {
      const metaElement = document.createElement('meta');
      metaElement.setAttribute('data-dynamic', '1');
      metaElement.setAttribute(currentPage.meta.elements[name].type, name);
      metaElement.setAttribute('content', currentPage.meta.elements[name].value);
      if (document.head) {
        document.head.appendChild(metaElement);
      }
    });

    Array.from(document.querySelectorAll('link[rel=alternate]')).forEach((node) => {
      node.parentElement.removeChild(node);
    });

    Object.keys(currentPage.alternateLinks || {}).forEach((id) => {
      const alternateElement = document.createElement('link');
      alternateElement.rel = 'alternate';
      alternateElement.hreflang = id;
      alternateElement.href = currentPage.alternateLinks[id];
      if (document.head) {
        document.head.appendChild(alternateElement);
      }
    });
  }
}

function formatHost(host: string) {
  const parts = host.split('.');
  if (parts.length !== 3) {
    return host;
  }

  return parts[1].substring(0, 1).toUpperCase() + parts[1].substring(1) + '.' + parts[2];
}

function isCanonicalResource(page: {}): page is Scope.ICanonicalResource {
  return page && 'canonicalUrl' in page;
}

export default connect((state): ConnectedPropType => {
  return {
    currentPage: state.currentPage,
    //pages: state.appShellData.pages,
    mainMenu: state.mainMenu,
    showLoadingSpinner: state.spinner.isVisible,
    currentBreakpoint: state.currentBreakpoint,
    quickSearchOpen: state.quickSearch.isOpen,
    accountBoxIsOpen: state.currentUser.loginMenuVisible,
    appShellData: state.appShellData,
  };
})(function SiteLayout(props: ConnectedPropType) {
  const { ...restProps } = props;
  return (
    <Layout {...restProps} />
  );
}
);